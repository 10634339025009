<template>
  <!-- 我的车辆 -->
  <div class="container">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getAlricleList">
      <div class="articles">
        <div class="item" v-for="(item, index) in list" :key="index" @click="goDetail(item.id)">
          <!-- <img :src="item.cover" alt=""> -->
          <van-image fit="cover" :src="item.cover" />
          <div class="right">
            <div class="title van-ellipsis">{{ item.title }}</div>
            <div class="desc">
              <van-tag size="medium" color="#d9edfd" text-color="#0584EF">{{ item.typeName }}</van-tag>
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>

  </div>
</template>

<script>
import { getNoticeList } from '@/api/eventApi'
export default {
  props: {
    category: {
      type: [Number],
      required: false,
      default: 0,
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      currentList: -1,
    }
  },

  created() {
    // this.getList()
  },
  mounted() {},
  methods: {
    getAlricleList() {
      if (this.currentList !== 0) {
        getNoticeList({
          current: this.page,
          status: 1,
          size: 10,
          isShare: 1, // 不返回社情共享
        }).then(res => {
          if (res.code === 200) {
            this.loading = false
            this.currentList = res.data.records ? res.data.records.length : 0
            this.list = this.list.concat(res.data.records)
            this.page++
          }
        })
      } else {
        this.finished = true
      }
    },
    goAdd() {
      this.$router.push({
        path: '/addCar',
      })
    },
    goDetail(id) {
      this.$router.push({
        // path: '/' + this.detailPath + '?id=' + id,
        path: '/preventionDetail?id=' + id,
      })
    },
    
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  padding: 20px 15px;
}

.van-cell__value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ml {
  margin-left: 5px;
}
.articles {
  margin-top: 12px;
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin-bottom: 12px;
    box-sizing: border-box;
    padding: 12px 8px;
    border-radius: 8px;
  }
  .van-image {
    width: 100px;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .right {
    height: 60px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      width: 200px;
      font-size: 12px;
      font-weight: 700;
    }
    .desc {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
.add {
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>
